.container {
  background-color: #f8f8f8;
  height: 100%;
  width: 100%;
}
.container .content {
  background-color: #f8f8f8;
  padding-bottom: 1rem;
}
.user_msg {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
  font-family: PingFang SC;
}
.user_msg .msg {
  padding: 0.4rem 0.2rem 0.16rem 0.2rem;
}
.user_msg .msg .msg-top {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8f1fe;
  padding-bottom: 0.32rem;
}
.user_msg .msg .msg-top .msg_left {
  margin-left: 1.34rem;
}
.user_msg .msg .msg-top .msg_left .img {
  width: 1.2rem;
  height: 1.2rem;
}
.user_msg .msg .msg-top .msg_left .img img {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}
.user_msg .msg .msg-top .msg_left .name {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .msg .msg-top .msg_right {
  margin-left: 0.4rem;
}
.user_msg .msg .msg-top .msg_right .name {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 500;
}
.user_msg .msg .msg-top .msg_right .VIP {
  display: flex;
  align-items: center;
}
.user_msg .msg .msg-top .msg_right .VIP .img_vip img {
  width: 0.252399rem;
  height: 0.2218rem;
}
.user_msg .msg .msg-top .msg_right .VIP .vip_number {
  margin-left: 0.1rem;
  font-size: 0.24rem;
  color: #333333;
}
.user_msg .msg .msg-top .msg_right .type {
  margin-top: 0.1rem;
  color: #5899f6;
  background-color: #e8f1fe;
  padding: 0.12rem 0.12rem 0.1rem 0.12rem;
  font-size: 0.24rem;
  text-align: center;
  font-weight: 600;
  border-radius: 0.08rem;
}
.user_msg .msg .msg-bottom {
  margin-top: 0.16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #5899f6;
  text-align: center;
}
.user_msg .msg .msg-bottom .msg-bottom-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  padding: 0.1rem 0 0.12rem 0;
  border-right: 1px solid #e8f1fe;
}
.user_msg .msg .msg-bottom .msg-bottom-left .Top {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .msg .msg-bottom .msg-bottom-left .Bottom {
  text-align: center;
  color: #666666;
  font-size: 0.2rem;
  font-weight: 600;
}
.user_msg .msg .msg-bottom .msg-bottom-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  padding: 0.1rem 0 0.1rem 0;
}
.user_msg .msg .msg-bottom .msg-bottom-center .Top {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .msg .msg-bottom .msg-bottom-center .Bottom {
  text-align: center;
  color: #666666;
  font-size: 0.2rem;
  font-weight: 600;
}
.user_msg .msg .msg-bottom .msg-bottom-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  padding: 0.1rem 0 0.1rem 0;
  border-left: 1px solid #e8f1fe;
}
.user_msg .msg .msg-bottom .msg-bottom-right .Top {
  text-align: center;
  color: #333333;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .msg .msg-bottom .msg-bottom-right .Bottom {
  text-align: center;
  color: #666666;
  font-size: 0.2rem;
  font-weight: 600;
}
.user_msg .shuju {
  padding: 0.32rem 0.64rem 0.12rem 0.64rem;
}
.user_msg .shuju .list1 {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  justify-content: space-between;
}
.user_msg .shuju .list1 .top_left {
  width: 40%;
  text-align: left;
}
.user_msg .shuju .list1 .top_right {
  width: 33%;
  text-align: left;
}
.user_msg .shuju .list1 .txt {
  color: #666666;
  font-size: 0.2rem;
}
.user_msg .shuju .list1 .left_num {
  color: #333333;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .shuju .list1 .right_num {
  color: #13ad8f;
  font-size: 0.28rem;
  font-weight: 600;
}
.user_msg .list_head {
  margin: 0 0.76rem;
  padding: 0.24rem 0;
  display: flex;
  justify-content: space-between;
}
.user_msg .list_head .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user_msg .list_head .item .top_txt {
  font-size: 0.28rem;
  color: #8b9fad;
  font-weight: 500;
}
.user_msg .list_head .item .active_bottom {
  margin-top: 0.08rem;
  width: 0.88rem;
  height: 0.08rem;
  background-color: #ffffff;
}
.user_msg .list_head .item .active_txt {
  color: #333333;
  font-weight: 600;
}
.user_msg .list_head .item .active_kuai {
  background-color: #5899f6;
}
.user_msg .list {
  margin: 0 0.68rem;
}
.user_msg .list .list_item {
  padding: 0.18rem 0;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.28rem;
  color: #333333;
  font-weight: 500;
}
.user_msg .item {
  margin-bottom: 0.2rem;
}
.user_msg .list1_top {
  margin: 0 0.2rem;
  background-color: rgba(232, 241, 254, 0.3);
  border-top-right-radius: 0.16rem;
  border-top-left-radius: 0.16rem;
}
.user_msg .list1_top .list1_top_item {
  padding: 0.2rem;
  display: flex;
  align-items: center;
}
.user_msg .list1_top .list1_top_item .item_name {
  font-size: 0.28rem;
  font-weight: 600;
  color: #333333;
}
.user_msg .list1_top .list1_top_item .item_name1 {
  font-size: 0.24rem;
  color: #999999;
}
.user_msg .list1_top .list1_top_item .item_time {
  color: #333333;
  transform: scale(0.83);
  font-size: 12px;
}
.user_msg .list1_top .list1_top_item .item_zuoduo span {
  color: #90c4ff;
  font-size: 0.2rem;
  padding: 0 0.1rem;
  line-height: 0.36rem;
  background: #f1f7ff;
  border-radius: 0.04rem;
  display: inline-block;
  margin-left: 0.1rem;
}
.user_msg .list1_top .list1_top_item .item_zuoduo span.gray {
  color: #9b9b9b;
  background: #f5f5f5;
}
.user_msg .list1_top .list1_top_item .item_zuoduo span.gray1 {
  color: #de5841;
  background: rgba(222, 88, 65, 0.09);
}
.user_msg .list1_top .list1_top_item .item_zuoduo span.gray2 {
  color: #4daa90;
  background: rgba(77, 170, 144, 0.09);
}
.user_msg .list1_top .list1_top_item .item_right .gray1 {
  color: #5899f6;
  font-size: 0.28rem;
  text-align: center;
}
.user_msg .dixian_kuai {
  margin: 0 0.2rem;
  background-color: rgba(232, 241, 254, 0.3);
}
.user_msg .dixian_kuai .dixian {
  margin: 0 auto;
  width: 5.9rem;
  height: 0.02rem;
  background-color: rgba(88, 153, 246, 0.08);
}
.user_msg .list1_bottom {
  margin: 0 0.2rem;
  padding: 0.2rem 0;
  background-color: rgba(232, 241, 254, 0.3);
  border-bottom-right-radius: 0.16rem;
  border-bottom-left-radius: 0.16rem;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_item {
  text-align: center;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_item .label {
  font-size: 0.24rem;
  transform: scale(0.83);
  color: #666666;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_item .value {
  color: #373737;
  font-size: 14px;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_item .green {
  color: #07ad91;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_item .red {
  color: #cf4e65;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_name {
  color: #373737;
  font-size: 0.36rem;
  font-family: PingFang SC;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  align-items: center;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.8rem;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_left {
  display: flex;
  align-items: center;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_left .chicang {
  font-size: 0.36rem;
  font-weight: 550;
  margin-right: 0.1rem;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_left .bucang {
  font-size: 0.22rem;
  color: #373737;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_right {
  display: flex;
  align-items: center;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_right .fiyin {
  font-size: 0.36rem;
  font-weight: 550;
  margin-left: 0.1rem;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_right .fudu {
  font-size: 0.24rem;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_right .green {
  color: #07ad91;
}
.user_msg .list1_bottom .listQuan_2 .listQuan_2_value .listQuan_2_value_right .red {
  color: #cf4e65;
}
.user_msg .list1_bottom1 {
  margin: 0 0.2rem;
  background-color: rgba(232, 241, 254, 0.3);
  border-bottom-right-radius: 0.16rem;
  border-bottom-left-radius: 0.16rem;
}
.user_msg .list1_bottom1 .listQuan_2 {
  margin: 0.2rem 0.66rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_item {
  text-align: left;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_item .label {
  font-size: 0.24rem;
  transform: scale(0.83);
  color: #666666;
  margin-left: -0.15rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_item .value {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_item .green {
  color: #07ad91;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_item .red {
  color: #cf4e65;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_name {
  color: #373737;
  font-size: 0.36rem;
  font-family: PingFang SC;
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  align-items: center;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 0.8rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_left {
  display: flex;
  align-items: center;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_left .chicang {
  font-size: 0.36rem;
  font-weight: 550;
  margin-right: 0.1rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_left .bucang {
  font-size: 0.22rem;
  color: #373737;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_right {
  display: flex;
  align-items: center;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_right .fiyin {
  font-size: 0.36rem;
  font-weight: 550;
  margin-left: 0.1rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_right .fudu {
  font-size: 0.24rem;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_right .green {
  color: #07ad91;
}
.user_msg .list1_bottom1 .listQuan_2 .listQuan_2_value .listQuan_2_value_right .red {
  color: #cf4e65;
}
.bottom {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  padding: 0;
}
.bottom .button {
  margin: 0 auto;
  margin-top: 0.14rem;
  width: 6.9rem;
  height: 0.88rem;
  line-height: 0.88rem;
  background-color: #5899f6;
  color: #ffffff;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.12rem;
  font-weight: 500;
}
.tan {
  width: 5.6rem;
  height: 3.6rem;
  background-image: url(../../assets/imgs/tan_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 3.6rem;
}
.tan .tan_no_txt {
  padding: 1.5rem 0.82rem 0 0.6rem;
  color: #333333;
  font-size: 0.28rem;
  text-align: center;
  font-weight: 500;
}
.tan .tan_no_txt1 {
  padding: 1.3rem 0.82rem 0 0.72rem;
  font-size: 0.2rem;
  text-align: left;
  font-weight: 500;
  color: #333333;
}
.tan .tan_no_txt2 {
  margin-top: 0.05rem;
  color: #333333;
  font-size: 0.16rem;
  transform: scale(0.75);
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: #666666;
  text-align: left;
}
.tan .tan_no_txt2 .right {
  color: #d98181;
}
.tan .input {
  padding: 0 0.7rem 0 0.72rem;
  color: #333333;
  font-weight: bold;
}
.tan .input input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 0.04rem 0;
  font-size: 0.3rem;
}
.tan .tan_no_button {
  margin: 0.3rem 1.2rem 0 1.32rem;
  padding: 0.1rem 0.36rem 0.08rem 0.36rem;
  font-size: 0.28rem;
  color: #ffffff;
  background-color: #5899f6;
  text-align: center;
  border-radius: 0.16rem;
}
.liebiao1 {
  padding-bottom: 0.4rem;
}
